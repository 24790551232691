define("ember-svg-jar/inlined/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icons/label</title><defs><path d=\"M2.02.192h8a2 2 0 012 2V13.55a1 1 0 01-.275.69L7.61 18.584a1 1 0 01-.722.31l-1.862.004a1 1 0 01-.737-.322l-4.002-4.34a1 1 0 01-.265-.678V2.192a2 2 0 012-2z\" id=\"a\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#211E5B\" stroke-width=\"1.5\" d=\"M14.198 4.095a1.25 1.25 0 00-1.768 0l-8.038 8.038a.25.25 0 00-.073.166l-.238 5.9a.25.25 0 00.073.186L5.473 19.7a.25.25 0 00.183.073l5.997-.148a.25.25 0 00.17-.073l8.031-8.031a1.25 1.25 0 000-1.768l-5.656-5.657z\"/><circle fill=\"#009FF1\" cx=\"8.5\" cy=\"15.5\" r=\"1.5\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});