define("ember-svg-jar/inlined/location-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 0c5.302 0 9.6 4.605 9.6 10.286 0 3.787-3.2 8.358-9.6 13.714-6.4-5.356-9.6-9.927-9.6-13.714C2.4 4.606 6.698 0 12 0zm0 1.2c-4.62 0-8.4 4.05-8.4 9.086 0 3.097 2.606 7.039 7.908 11.71l.492.428.068-.057c5.451-4.716 8.196-8.704 8.327-11.847l.005-.234C20.4 5.25 16.62 1.2 12 1.2zM12 6a3.6 3.6 0 110 7.2A3.6 3.6 0 0112 6zm0 1.2a2.4 2.4 0 10.001 4.801A2.4 2.4 0 0012 7.2z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});