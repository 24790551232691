define("ember-svg-jar/inlined/logo-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.047 94.21C.047 42.237 42.181.103 94.155.103c51.975 0 94.108 42.134 94.108 94.107 0 51.975-42.133 94.108-94.108 94.108S.047 146.184.047 94.21zm39.725-28.33c0 9.656 10.09 19.363 43.946 23.817l1.075.138 1.577.224c25.558 3.706 41.02 10.08 48.754 20.902l12.47-38.096c3.952-12.064-5.02-24.471-17.73-24.524l-71.287-.299C48.175 48 40.213 56.251 39.772 65.88zm7.687 27.967l13.488 42.395a18.734 18.734 0 0017.405 13.04l.374.005 30.138.126.452-.006c4.98-.123 15.166-2.39 18.866-17.246 3.696-14.849-8.469-24.84-49.342-30.307-12.783-1.518-22.975-4.078-30.837-7.749l-.544-.258z\"/>",
    "attrs": {
      "viewBox": "0 0 189 189",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});