define("ember-svg-jar/inlined/money-check-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M608 32H32C14.33 32 0 46.33 0 64v384c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 416H32V64h576v384zM296 320h80c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-80c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm240-32h-80c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h80c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-240-64h240c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H296c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm-161.28 33.72l42.19 11.44c4.19 1.14 7.09 4.55 7.09 8.3 0 4.8-4.5 8.7-10.06 8.7H147.6c-4.15 0-8.23-1.04-11.77-2.95-3.08-1.67-6.84-1.37-9.24 1.18l-12.07 12.73c-3.11 3.28-2.6 8.64 1.13 11.19 8.3 5.65 18.06 8.88 28.35 9.52V328c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-10.25c22.18-1.1 40-18.57 40-40.3 0-18.17-12.62-34.28-30.72-39.17l-42.19-11.44c-4.19-1.14-7.09-4.55-7.09-8.3 0-4.8 4.5-8.7 10.06-8.7h26.34c4.15 0 8.23 1.04 11.77 2.95 3.08 1.66 6.84 1.37 9.24-1.18l12.07-12.73c3.11-3.28 2.6-8.64-1.13-11.19-8.3-5.65-18.06-8.88-28.35-9.52V168c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v10.25c-22.18 1.1-40 18.57-40 40.3 0 18.17 12.62 34.28 30.72 39.17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 512"
    }
  };
});