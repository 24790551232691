define("ember-svg-jar/inlined/dark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#AFB8C3\" fill-rule=\"evenodd\" d=\"M0 6c0-3.316 2.757-6 6.154-6 .377 0 .754.034 1.125.1a.28.28 0 01.09.521c-1.51.838-2.44 2.395-2.44 4.088 0 2.938 2.742 5.17 5.728 4.617.262-.049.448.25.278.453A6.197 6.197 0 016.155 12C2.752 12 0 9.312 0 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "12",
      "viewBox": "0 0 11 12"
    }
  };
});