define("ember-svg-jar/inlined/digitick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.605 12.649c1.198 0 2.186-.967 2.335-2.215V9.78c-.15-1.25-1.137-2.216-2.335-2.216-1.302 0-2.357 1.138-2.357 2.542 0 1.403 1.055 2.542 2.357 2.542zM28.94 4.68V.079h4.328v16.304H28.94v-.677a6.156 6.156 0 110-11.025zM34.013 16.383H37.8V4.612h-3.788v11.771zM34.013 3.327H37.8V.08h-3.788v3.247zM44.037 11.107a1.635 1.635 0 11.002-3.271 1.635 1.635 0 01-.002 3.27zm4.09-5.275l1.086-1.558h-3.415a5.493 5.493 0 00-1.794-.304 5.514 5.514 0 100 11.027c.504 0 .991-.073 1.455-.2.06.278.271 1.677-1.433 1.677-1.78 0-3.473-1.376-3.473-1.376l-1.78 2.91S41.861 20.45 45 19.935c3.16-.519 4.905-2.826 4.303-5.76-.125-.617-.415-1.24-.67-1.704.558-.86.884-1.885.884-2.987a5.48 5.48 0 00-1.39-3.652zM50.233 16.383h3.79V4.612h-3.79v11.771zM50.233 3.327h3.79V.08h-3.79v3.247zM62.192 16.475h3.816V4.621h-3.816v11.854zM80.45 10.865l.274.002 2.825 5.604h4.724l-2.872-6.377 2.452-5.632s-1.998-.046-3.588-.046c-1.244 0-1.453.682-1.68 1.136-.226.454-1.952 4.042-1.952 4.042l-.182.002V.056h-3.815v16.42h3.815v-5.611zM62.192 3.327h3.816V.057h-3.816v3.27zM59.83 11.366V7.658h1.5V4.621H59.82l-.036-2.93-3.68 2.044.005.886h-.958v3.037h.974c.012 2.598.026 5.59.026 5.933 0 .62.499 2.906 2.361 2.906s3.043-.045 3.043-.045v-3.86s-1.725.227-1.725-1.226zM75.925 16.272V12.91s-5.36 1.273-5.36-2.997c0-2.954 5.133-2.226 5.133-2.226V4.28s-8.812-1.635-8.812 5.95c0 7.586 9.04 6.042 9.04 6.042zM4.669 12.349h4.08V8.268h-4.08v4.08zM12.829.107H8.75v4.08h-4.08V.107H.587v4.08h4.08v4.081H.587v8.162H8.75v-4.058h4.045v4.116h4.081V4.193H12.83V.107z\"/>",
    "attrs": {
      "viewBox": "0 0 89 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});