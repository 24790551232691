define("ember-svg-jar/inlined/ticketweb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M70.362 8.216l-2.098 6.338h-.044L66.035 8.98h-.546l-2.186 5.573h-.043L59.304 3.453h-5.07V1.07h-2.49v2.382H49.69V5.79h2.054v6.665c0 1.42.852 2.84 2.906 2.84h1.42v-2.338h-.961c-.634 0-.874-.306-.874-.896V5.792h3.343l5.135 14.204h.525l2.535-6.097h.043l2.535 6.097h.525l3.474-9.659h4.414v-2.12h-6.402zM9.962 15.318h2.535v-11.8H9.962v11.8zM23.685 7.386c-.677-1.049-1.638-1.792-3.059-1.792-2.229 0-3.824 1.705-3.824 3.824 0 2.12 1.617 3.824 3.824 3.824 1.399 0 2.382-.699 3.06-1.791l2.206 1.31c-1.048 1.662-3.037 2.863-5.288 2.863-4.13 0-6.512-3.103-6.512-6.228 0-3.124 2.382-6.227 6.512-6.227 2.513 0 4.152 1.201 5.288 2.862l-2.207 1.311v.044zM37.846 15.318L31.902 9.44l4.917-5.638h-2.71l-4.283 4.72V.087H27.29v15.231h2.535v-5.026l4.938 5.026h3.082zM5.92 12.958c-.634 0-.875-.306-.875-.896V5.792h3.213V3.453H5.045V1.07h-2.49v2.382H.5V5.79h2.054v6.665c0 1.42.852 2.84 2.906 2.84h1.42v-2.338h-.96z\"/><path d=\"M46.346 8.391c-.525-1.77-1.836-2.688-3.41-2.688-1.572 0-2.862.984-3.277 2.688h6.687zm2.294 4.458c-1.376 1.748-3.3 2.797-5.375 2.819-4.021 0-6.381-3.037-6.381-6.075 0-3.06 2.338-6.075 6.38-6.075 2.404 0 5.988 1.989 5.988 6.49v.46H39.66c.153 1.77 1.792 3.037 3.54 3.037 1.333 0 2.47-.634 3.343-1.792l2.098 1.158v-.022zM97.983 9.462c0-2.098-1.683-3.78-3.715-3.78-1.989 0-3.671 1.682-3.671 3.78s1.682 3.78 3.67 3.78c2.033 0 3.716-1.682 3.716-3.78zm-7.277 5.835h-2.513V.152h2.513v4.895h.044c.655-.984 1.988-1.726 3.715-1.726 3.78 0 6.184 3.277 6.184 6.162 0 3.015-2.23 6.14-6.053 6.14-1.596 0-3.038-.677-3.846-1.726h-.044v1.42-.021zM83.844 8.216c-.546-1.813-1.88-2.753-3.496-2.753-1.617 0-2.928 1.005-3.344 2.753h6.84zm2.338 4.568c-1.42 1.77-3.365 2.862-5.484 2.884-4.13 0-6.512-3.103-6.512-6.228 0-3.125 2.381-6.228 6.511-6.228 2.47 0 6.12 2.033 6.12 6.643v.46h-9.813c.153 1.813 1.836 3.102 3.606 3.102 1.377 0 2.535-.655 3.409-1.835l2.141 1.201h.022z\"/>",
    "attrs": {
      "viewBox": "0 0 101 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});