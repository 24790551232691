define("ember-svg-jar/inlined/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file_icon</title><path d=\"M199.552 89.74V0H40.096C18.316 0 0 18.504 0 40.428v324.153C0 386.91 17.928 405 40.096 405h240.817c21.77 0 40.087-18.504 40.087-40.42V122.474h-88.975c-17.906 0-32.473-14.688-32.473-32.733zm56.573-67.258L233.828 0h-9.243v89.74c0 4.145 3.343 7.493 7.435 7.493H321v-9.311l-64.875-65.44z\"/>",
    "attrs": {
      "width": "321",
      "height": "405",
      "viewBox": "0 0 321 405",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});