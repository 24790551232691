define("ember-svg-jar/inlined/safari-pinned-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M243.622 131.673c.25-3.191.378-6.417.378-9.673C244 54.621 189.379 0 122 0 54.621 0 0 54.621 0 122c0 67.379 54.621 122 122 122 54.063 0 99.912-35.165 115.923-83.87-29.918 14.852-76.375 13.552-83.792 12.073-7.287 22.047-30.013 38.012-62.62 38.012-22.357 0-39.896-9.756-39.896-27.115 0-17.36 17.786-29.27 52.988-29.27 8.645 0 16.921.38 24.579 1.14-1.235-8.489-5.064-13.304-14.575-23.948l-2.223-2.534c-15.316-17.106-21.738-29.777-21.738-46.882 0-24.835 19.021-46.883 47.058-46.883 28.038 0 39.895 17.613 39.895 32.31 0 11.025-6.67 19.26-15.563 19.26-7.905 0-12.722-5.194-12.722-12.417 0-6.335 4.323-11.024 9.634-12.67-2.099-6.97-8.769-13.685-21.491-13.685s-23.961 8.87-23.961 24.328c0 11.277 5.064 18.5 15.809 30.917l6.67 7.729c15.563 17.486 20.75 29.904 20.75 45.869v2.788c6.852 1.382 52.856-2.056 86.897-27.479zM90.275 194.63c-13.71 0-21.738-5.195-21.738-12.671 0-8.489 8.646-14.571 35.572-14.571 9.14 0 17.538.38 25.073 1.14-2.594 15.712-16.304 25.975-39.03 25.975l.123.127z\" fill=\"#000\"/>",
    "attrs": {
      "width": "244",
      "height": "244",
      "viewBox": "0 0 244 244",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});