define("ember-svg-jar/inlined/light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#AFB8C3\" fill-rule=\"evenodd\" d=\"M6.927 10.923c.336 0 .613.277.613.612v1.692c0 .32-.277.598-.613.598a.603.603 0 01-.612-.598v-1.706c0-.321.277-.598.612-.598zm-3.69-1.167a.574.574 0 01.846 0 .614.614 0 010 .86l-1.21 1.211a.584.584 0 01-.423.175.608.608 0 01-.423-.175.616.616 0 010-.86zm6.52 0a.616.616 0 01.86 0l1.195 1.21a.616.616 0 010 .861.58.58 0 01-.422.175.606.606 0 01-.423-.175l-1.21-1.21a.615.615 0 010-.86zm-2.83-5.92a3.086 3.086 0 013.077 3.076c0 1.692-1.385 3.078-3.077 3.078S3.85 8.604 3.85 6.912a3.087 3.087 0 013.077-3.077zM2.304 6.314c.336 0 .613.262.613.597a.6.6 0 01-.598.598H.613A.612.612 0 010 6.912c0-.32.277-.597.613-.597zm10.938 0c.335 0 .627.277.627.597a.613.613 0 01-.613.598H11.55a.614.614 0 01-.613-.598c0-.32.278-.597.613-.597zm-2.275-4.303a.616.616 0 01.86 0 .616.616 0 010 .86l-1.21 1.211a.584.584 0 01-.423.175.723.723 0 01-.438-.175.616.616 0 010-.86zm-8.954-.014a.616.616 0 01.86 0l1.21 1.21a.614.614 0 010 .86.582.582 0 01-.423.176.614.614 0 01-.423-.175l-1.224-1.21a.616.616 0 010-.861zM6.927 0c.336 0 .613.263.613.598v1.706a.613.613 0 01-1.225 0V.598c0-.32.277-.598.612-.598z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
});