define("ember-svg-jar/inlined/in-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.11.89l-1.63 1.63A7.75 7.75 0 00.26 7.61C.25 7.822.42 8 .634 8h.877a.375.375 0 00.374-.353A6.122 6.122 0 018 1.875c1.693 0 3.224.685 4.332 1.793L10.64 5.36a.375.375 0 00.265.64h4.47a.375.375 0 00.375-.375v-4.47a.375.375 0 00-.64-.265zM15.366 8h-.877a.375.375 0 00-.374.353A6.122 6.122 0 018 14.125a6.105 6.105 0 01-4.332-1.793L5.36 10.64a.375.375 0 00-.265-.64H.625a.375.375 0 00-.375.375v4.47c0 .334.404.501.64.265l1.63-1.63A7.75 7.75 0 0015.74 8.39.374.374 0 0015.366 8z\" fill=\"#151515\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});