define("ember-svg-jar/inlined/tm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_502_648)\"><path d=\"M.34 2.842h1.688l.409-1.824L4.873.2 4.26 2.828h2.015l-.34 1.688H3.92L3.103 8.3a2.995 2.995 0 00-.135.872c0 .544.34.871.871.871.408 0 .817-.068 1.075-.204l-.408 1.756c-.408.068-.817.204-1.211.204-1.552 0-2.628-.613-2.628-2.3 0-.68.136-1.348.273-2.028l.68-2.967H0l.34-1.662zm7.308 1.184l.204-.94h2.095L9.743 4.23c.613-.872 1.62-1.348 2.695-1.348 1.01 0 2.096.613 2.233 1.688h.068c.612-1.143 1.824-1.688 3.035-1.688 1.28 0 2.436 1.076 2.436 2.369 0 .816-.204 1.688-.408 2.368l-.94 4.179h-2.299l.94-4.247c.135-.544.272-1.347.272-1.895 0-.68-.474-1.075-1.076-1.075-1.551 0-1.96 1.824-2.232 3.035l-.872 4.111h-2.3l.939-4.243c.136-.545.272-1.348.272-1.895 0-.68-.474-1.075-1.075-1.075-1.552 0-1.96 1.824-2.233 3.035l-.871 4.11h-2.3l1.62-7.633z\" fill=\"#151515\"/></g><defs><clipPath id=\"clip0_502_648\"><path fill=\"#fff\" transform=\"translate(0 .201)\" d=\"M0 0h20.21v11.598H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 21 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});