define("ember-data-has-many-query/belongs-to-sticky", ["exports", "@ember/object", "ember-data", "ember-data-has-many-query/property-names"], function (_exports, _object, _emberData, _propertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.recordHasId = _exports.default = void 0;
  const recordHasId = function (record) {
    return record && record.get('id');
  };

  /**
   * Create an extension to the `DS.belongsTo` computed property that returns a cached
   * record if the current associated belongsTo record doesn't have an id.
   *
   * This may be useful if querying a hasMany relationship multiple times and storing
   * the results, as each query will reset the ManyArray and therefore remove the inverse
   * belongsTo association. Defining a relationship as `belongsToSticky` will keep the
   * associated record even if it is removed from the ManyArray.
   *
   * @returns {Ember.computed} relationship
   */
  _exports.recordHasId = recordHasId;
  const belongsToSticky = function () {
    let _computed = _emberData.default.belongsTo(...arguments);
    let meta = _computed.meta();
    meta.sticky = true;
    return (0, _object.computed)({
      get: function (key) {
        let value = _computed._getter.call(this, ...arguments);
        if (recordHasId(value)) {
          return value;
        }
        return this.get((0, _propertyNames.stickyPropertyName)(key)) || value;
      },
      set: function (key) {
        this.set((0, _propertyNames.stickyPropertyName)(key), undefined);
        return _computed._setter.call(this, ...arguments);
      }
    }).meta(meta);
  };
  var _default = _exports.default = belongsToSticky;
});