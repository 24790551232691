define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icons/calendar</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#009FF1\" fill-rule=\"nonzero\" d=\"M3.72 9.75v-1.5h16.56v1.5z\"/><circle fill=\"#23205B\" cx=\"8.5\" cy=\"5.5\" r=\"1.5\"/><circle fill=\"#23205B\" cx=\"15.5\" cy=\"5.5\" r=\"1.5\"/><circle fill=\"#23205B\" cx=\"10\" cy=\"16\" r=\"1\"/><circle fill=\"#23205B\" cx=\"8\" cy=\"12\" r=\"1\"/><circle fill=\"#009FF1\" cx=\"17\" cy=\"12\" r=\"1\"/><circle fill=\"#009FF1\" cx=\"17\" cy=\"14\" r=\"1\"/><circle fill=\"#009FF1\" cx=\"17\" cy=\"16\" r=\"1\"/><path stroke=\"#23205B\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M4 6h16v13H4z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});