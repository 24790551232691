define("ember-svg-jar/inlined/tiktok", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M75.463 156.699H37.732V34.052H.072L0 .432h116.297l-10.677 33.62H75.463V156.7zm306.832 0h-37.66V34.052h-37.803L306.76.432h118.967l-10.678 33.62h-32.754V156.7zM123.368 50.14h37.299v106.558h-37.01l-.29-106.558zm100.353 54.685l-11.038 10.678v41.195h-37.227V0h37.227v72.794l36.938-36.072h44.441l-46.677 45.307 52.305 74.67h-41.05l-34.919-51.873zm394.632 0l-10.677 10.678v41.195h-37.3L570.017 0h37.66v72.794l36.866-36.072h44.441l-46.678 45.307 51.945 74.67H653.2l-34.847-51.873z\"/><circle cx=\"142.053\" cy=\"19.191\" r=\"18.758\"/><path d=\"M420.965 92.923c.012-33.284 25.616-60.962 58.798-63.56h-5.555c-33.723 1.967-60.062 29.888-60.062 63.668s26.34 61.7 60.062 63.668h5.555c-33.265-2.605-58.9-30.41-58.798-63.776zM494.264 29.147h-5.627c33.074 2.731 58.526 30.372 58.526 63.56 0 33.186-25.452 60.827-58.526 63.559h5.627c35.223 0 63.776-28.554 63.776-63.776 0-35.223-28.553-63.776-63.776-63.776v.433z\"/><path d=\"M484.164 123.945c-17.133 0-31.022-13.89-31.022-31.022 0-17.134 13.889-31.023 31.022-31.023s31.022 13.89 31.022 31.023c-.04 17.116-13.906 30.982-31.022 31.022zm0-94.798c-35.223 0-63.776 28.553-63.776 63.776 0 35.222 28.553 63.776 63.776 63.776 35.222 0 63.776-28.554 63.776-63.776a63.776 63.776 0 00-63.776-63.776z\"/>",
    "attrs": {
      "viewBox": "0 0 696 158",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});