define("ember-parachute/-private/parachute-event", ["exports", "@ember/array", "ember", "ember-parachute/query-params"], function (_exports, _array, _ember, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    canInvoke
  } = _ember.default;
  const {
    keys
  } = Object;

  /**
   * Change event generated by query params changing.
   *
   * @export
   * @class ParachuteEvent
   */
  class ParachuteEvent {
    /**
     * Creates an instance of ParachuteEvent.
     *
     * @param {string} routeName
     * @param {Ember.Controller} controller
     * @param {object} [changed={}]
     *
     * @memberof ParachuteEvent
     */
    constructor(routeName, controller, changed = {}) {
      let {
        queryParams,
        queryParamsArray
      } = _queryParams.default.metaFor(controller);
      let state = _queryParams.default.stateFor(controller);
      let changedKeys = (0, _array.A)(keys(changed));

      /**
       * The route the event was fired from
       */
      this.routeName = routeName;

      /**
       * All query params that have changed from this update event
       */
      this.changed = queryParamsArray.reduce((changedParams, qp) => {
        if (changedKeys.includes(qp.as)) {
          changedParams[qp.key] = canInvoke(qp, 'deserialize') ? qp.deserialize(changed[qp.as], controller) : changed[qp.as];
        }
        return changedParams;
      }, {}, undefined);

      /**
       * All Query Params at this given moment
       */
      this.queryParams = _queryParams.default.queryParamsFor(controller);

      /**
       * Whether or not a model refresh should occur
       */
      this.shouldRefresh = (0, _array.A)(keys(this.changed)).any(key => queryParams[key].refresh);

      /**
       * All query params that are not their default
       */
      this.changes = keys(state).reduce((changes, key) => {
        if (state[key].changed) {
          changes[key] = state[key].value;
        }
        return changes;
      }, {});
    }
  }
  _exports.default = ParachuteEvent;
});