define("ember-svg-jar/inlined/time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.062 12.667a5.667 5.667 0 100-11.334 5.667 5.667 0 000 11.334z\" stroke=\"#151515\" stroke-width=\"1.5\"/><path d=\"M7.062 7H9.73M7.062 7V3.267\" stroke=\"#151515\" stroke-width=\"1.5\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});