define("ember-svg-jar/inlined/tshirt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M606.4 111.6c-2.6-7.8-8-14.1-15.2-17.8L403.8 0l-7.9 11.1c-15.7 22-51.8 36.2-91.9 36.2s-76.2-14.2-91.9-36.2L204.2 0 16.8 93.8c-7.3 3.7-12.7 10-15.2 17.8-2.6 7.8-2 16.1 1.7 23.4l51.5 104c3.6 7.4 9.8 12.9 17.5 15.4 7.7 2.7 16 2 23.2-1.6l48.6-24v237.8c0 25 20.1 45.3 44.8 45.3h230.4c24.7 0 44.8-20.3 44.8-45.3V228.9l48.5 24c7.2 3.7 15.5 4.3 23.3 1.6 7.7-2.6 13.9-8.1 17.5-15.4L604.9 135c3.5-7.3 4.1-15.6 1.5-23.4zm-79.7 112.3L432 177.1v289.7c0 7.1-5.7 12.9-12.8 12.9H188.8c-7.1 0-12.8-5.8-12.8-12.9V177.1l-92.6 47.6-52.3-101.9 164.1-82.2c24 24.3 64.2 39 108.9 39S389 64.9 413 40.6L575.5 122l-48.8 101.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 608 512"
    }
  };
});