define("ember-svg-jar/inlined/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.21 14.709a8.03 8.03 0 01-2.366-5.73 8.077 8.077 0 012.383-5.735 8.11 8.11 0 018.298-1.972l-.481 1.443A6.6 6.6 0 004.3 4.317c-2.572 2.572-2.578 6.75-.013 9.315 2.564 2.564 6.743 2.558 9.315-.013a6.598 6.598 0 001.602-6.744l1.443-.481a8.124 8.124 0 01-1.972 8.298 8.073 8.073 0 01-5.736 2.383 8.031 8.031 0 01-5.729-2.366z\"/>",
    "attrs": {
      "viewBox": "0 0 18 18"
    }
  };
});