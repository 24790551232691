define("ember-svg-jar/inlined/rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Icon</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M14.5 7a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm.848 3.355a1.208 1.208 0 01-2.094-.847 1.238 1.238 0 012.094-.857 1.21 1.21 0 010 1.71v-.006z\" fill=\"#211E5B\"/><path d=\"M3.83 19.2L1.2 21.827a.687.687 0 00.97.972L4.8 20.168a.684.684 0 00-.968-.968H3.83z\" fill=\"#009FF1\"/><path d=\"M23.825.175a.6.6 0 00-.502-.17l-5.77.708c-.795.1-1.533.463-2.098 1.03L13.617 3.58l-3.11-.204a4.176 4.176 0 00-3 .979L3.213 7.97a.601.601 0 00.212 1.032l3.658 1.102-1.154 1.17a.6.6 0 000 .848l.15.15-1.853 1.006a1.503 1.503 0 00-.339 2.377l4.459 4.466a1.502 1.502 0 002.382-.339l1.01-1.842.15.15a.6.6 0 00.849 0l1.167-1.167 1.1 3.66a.6.6 0 00.453.414.72.72 0 00.123 0 .604.604 0 00.46-.213l3.616-4.29a4.18 4.18 0 00.978-3l-.213-3.107 1.838-1.838a3.622 3.622 0 001.026-2.1l.71-5.772a.6.6 0 00-.17-.502zM4.832 8.177l3.452-2.913a2.998 2.998 0 012.144-.687l2.06.14L8.05 9.144l-3.218-.966zm4.837 11.026a.3.3 0 01-.473.07L4.73 14.81a.3.3 0 01.069-.474l2.147-1.179 3.899 3.9-1.176 2.145zm9.756-5.625a2.998 2.998 0 01-.699 2.138l-2.9 3.46-.965-3.22 4.435-4.436.129 2.058zm2.657-7.28a2.399 2.399 0 01-.685 1.406l-9.09 9.11-5.115-5.118 9.101-9.106a2.399 2.399 0 011.404-.683l5.026-.63-.641 5.022z\" fill=\"#211E5B\"/><path d=\"M4.15 23.856a.494.494 0 00.696 0l1.01-1.019a.49.49 0 000-.693.493.493 0 00-.695 0l-1.023 1.019a.492.492 0 00.012.694zM.5 19.99a.504.504 0 00.355-.148L1.9 18.8a.5.5 0 00-.051-.655.505.505 0 00-.658-.042L.146 19.145A.503.503 0 00.5 20v-.01z\" fill=\"#009FF1\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});