define("ember-svg-jar/inlined/seated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.424.041L2.594 0A2.586 2.586 0 000 2.464c0 1.347 1.42 2.7 6.203 3.307h.005c3.655.508 5.853 1.393 6.941 2.918l1.72-5.26A2.584 2.584 0 0012.424.04zm-.232 11.576c.51-2.05-1.168-3.43-6.804-4.185-1.804-.215-3.234-.58-4.328-1.106l1.86 5.855a2.584 2.584 0 002.452 1.802L9.528 14c.661 0 2.138-.267 2.664-2.383z\" fill=\"#151515\"/>",
    "attrs": {
      "viewBox": "0 0 15 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});