define("ember-svg-jar/inlined/ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icons/ticket</title><g fill=\"none\" fill-rule=\"evenodd\"><g stroke=\"#211E5B\" stroke-width=\"1.5\"><path d=\"M15.267 3.43a1.25 1.25 0 00-1.768 0l-6.994 6.993-1.185 1.119-.723.79-.79.79a1.25 1.25 0 000 1.769l5.33 5.331a1.25 1.25 0 001.769 0l.778-.778.98-1.025.956-.91 6.979-6.98a1.25 1.25 0 000-1.768L15.267 3.43z\"/><path d=\"M1.884 13.34h10.258\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"2,2\" mask=\"url(#mask-2)\" transform=\"rotate(45 6.113 17.012)\"/></g><path d=\"M13.68 6.814a.721.721 0 011.02 1.02l-3.848 3.847a.721.721 0 01-1.02-1.02l3.847-3.847zM14.36 10.544a.721.721 0 011.02 1.02l-1.924 1.924a.721.721 0 01-1.02-1.02l1.923-1.924z\" fill=\"#009FF1\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});