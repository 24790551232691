define("ember-svg-jar/inlined/money-bill-wave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M320 160.55c-44.18 0-80 43.16-80 96.41 0 53.24 35.81 96.41 80 96.41 44.17 0 80-43.15 80-96.41 0-53.25-35.82-96.41-80-96.41zM621.16 54.46C582.37 38.19 543.55 32 504.75 32c-123.17-.01-246.33 62.34-369.5 62.34-30.89 0-61.76-3.92-92.65-13.72-3.47-1.1-6.95-1.62-10.35-1.62C15.04 79 0 92.32 0 110.81v317.26c0 12.63 7.23 24.6 18.84 29.46C57.63 473.81 96.45 480 135.25 480c123.17 0 246.34-62.35 369.51-62.35 30.89 0 61.76 3.92 92.65 13.72 3.47 1.1 6.95 1.62 10.35 1.62 17.21 0 32.25-13.32 32.25-31.81V83.93c-.01-12.64-7.24-24.6-18.85-29.47zM592 322.05c-26.89 3.4-48.58 23.31-54.38 49.48-10.8-.92-21.56-1.88-32.87-1.88-67.56 0-133.13 16.59-196.53 32.64C247.86 417.57 190.85 432 135.25 432c-8.02 0-15.85-.32-23.51-.94-1.42-34.23-29.29-61.61-63.73-61.61V192.69c31.07 0 56.93-22.25 62.74-51.75 8.14.51 16.08 1.4 24.51 1.4 67.56 0 133.12-16.59 196.52-32.64C392.13 94.43 449.14 80 504.75 80c10.84 0 21.22.78 31.42 1.91.85 31.96 24.87 57.84 55.83 61.76v178.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 512"
    }
  };
});