define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M5.349 4.5l2.564-2.564.528-.529a.197.197 0 000-.282L7.876.559a.199.199 0 00-.283 0L4.5 3.652 1.407.559a.197.197 0 00-.282 0l-.566.565a.199.199 0 000 .283L3.652 4.5.559 7.593a.199.199 0 000 .283l.565.565a.199.199 0 00.283 0L4.5 5.35l2.564 2.564.529.528a.199.199 0 00.283 0l.565-.565a.199.199 0 000-.283L5.35 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 9 9"
    }
  };
});