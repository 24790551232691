define("ember-svg-jar/inlined/venue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icons/venue</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M12.25 22.75c-3.832 0-7-1.267-7-3.25s3.168-3.25 7-3.25 7 1.267 7 3.25-3.168 3.25-7 3.25zm0-1.5c3.072 0 5.5-.971 5.5-1.75s-2.428-1.75-5.5-1.75-5.5.971-5.5 1.75 2.428 1.75 5.5 1.75z\" fill=\"#009FF1\" fill-rule=\"nonzero\"/><path d=\"M12.263 19c4.176-4.852 6.263-8.43 6.263-10.737A6.263 6.263 0 006 8.263C6 10.57 8.088 14.148 12.263 19z\" stroke=\"#23205B\" stroke-width=\"1.5\" fill=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle stroke=\"#211E5B\" stroke-width=\"1.5\" cx=\"12.25\" cy=\"8.25\" r=\"1.25\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});