define("ember-svg-jar/inlined/veeps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M55.314 12.163a1.69 1.69 0 001.691-1.692c0-.946-.756-1.692-1.691-1.692-.946 0-1.692.746-1.692 1.692 0 .935.746 1.692 1.692 1.692zM7.348 11.859L11.175.562H8.003L5.695 9.14h-.062L3.313.562H.06L3.917 11.86h3.431zm8.174.242c2.756 0 4.752-1.486 5.085-3.699H17.82c-.28.864-1.133 1.423-2.246 1.423-1.55 0-2.558-1.107-2.558-2.719v-.19H20.7V5.98c0-3.436-2.048-5.66-5.292-5.66-3.297 0-5.376 2.34-5.376 5.944 0 3.615 2.059 5.838 5.49 5.838zm2.301-7.07h-4.731c.104-1.413 1.06-2.435 2.391-2.435 1.352 0 2.278.99 2.34 2.434zm8.654 7.07c2.756 0 4.752-1.486 5.085-3.699h-2.787c-.28.864-1.133 1.423-2.246 1.423-1.549 0-2.558-1.107-2.558-2.719v-.19h7.685V5.98c0-3.436-2.049-5.66-5.293-5.66-3.296 0-5.376 2.34-5.376 5.944 0 3.615 2.059 5.838 5.49 5.838zm2.301-7.07h-4.73c.103-1.413 1.06-2.435 2.39-2.435 1.353 0 2.278.99 2.34 2.434zm6.651 10.516V9.962h.063c.592 1.275 1.84 2.076 3.462 2.076 2.787 0 4.513-2.192 4.513-5.828 0-3.646-1.736-5.827-4.565-5.827-1.611 0-2.849.822-3.452 2.15h-.062V.563h-2.995v14.984h3.036zm2.454-5.986c-1.487 0-2.464-1.306-2.464-3.35 0-2.024.977-3.362 2.464-3.362 1.518 0 2.475 1.317 2.475 3.361 0 2.055-.956 3.351-2.475 3.351zm10.215 2.54c2.943 0 4.96-1.465 4.96-3.688 0-1.686-.904-2.582-3.14-3.088l-2.132-.463c-.977-.232-1.455-.612-1.455-1.17 0-.727.686-1.212 1.736-1.212 1.123 0 1.84.559 1.903 1.465h2.797C52.726 1.753 50.906.32 48.11.32c-2.86 0-4.7 1.412-4.7 3.562 0 1.665 1.081 2.781 3.192 3.266l2.059.464c.998.242 1.373.558 1.373 1.127 0 .738-.697 1.202-1.851 1.202-1.207 0-1.945-.548-2.09-1.497h-2.964c.166 2.203 1.997 3.657 4.97 3.657z\"/>",
    "attrs": {
      "viewBox": "0 0 58 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});