define("ember-svg-jar/inlined/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icons/add</title><g fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke=\"#00D08A\"><path d=\"M12 6v12\"/><path d=\"M18 12H6\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});