define("ember-svg-jar/inlined/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.217 271.974v29.095h.221l-.01.145 271.355.005v-29.245zM136.005 249.5l.1.104 94.609-121.243h-71.263L159.455.263H113l-.455 128.098H41.286l94.613 121.243z\"/>",
    "attrs": {
      "width": "272",
      "height": "302",
      "viewBox": "0 0 272 302",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});