define("ember-svg-jar/inlined/twitch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M906.827 218.169H842.02v-80.805H825.82v80.805h-64.806V16.2h64.806v56.527h48.604l32.403 32.322V218.17m-161.933-80.805h-64.806v16.201h64.806v64.644h-97.16l-32.404-32.322V105.05l32.403-32.322h97.16v64.636m-145.74 0h-56.705v16.202h56.705v64.643h-89.06l-32.402-32.321V16.2h64.806v56.527h56.705l-.049 64.636M461.514 56.567h-64.806V16.201h64.806v40.366m0 161.602h-64.806V72.729h64.806v145.44m-81.007 0h-194.28l-32.403-32.322V72.728h64.806v80.797h16.202V72.728h64.806v80.797h16.2V72.728h64.807l-.138 145.441m-242.86-80.805h-56.64v16.201h56.705v64.644H48.604l-32.403-32.322V16.201h64.806v56.527h56.705l-.065 64.636m744.894-80.797h-40.504V0h-88.872L712.28 56.567h-72.64L615.34 80.805V56.567h-56.705V0H380.547v56.567H129.952L89.108 0H0v193.931l113.353 113.086h113.354v-32.321l32.403 32.321h218.597v-32.321l32.403 32.321h105.237v-32.321l32.403 32.321h202.42l72.907-72.728V96.965L882.54 56.567\"/>",
    "attrs": {
      "viewBox": "0 0 924 308",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});