define("ember-svg-jar/inlined/layer-shape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.685 1H.388A.185.185 0 01.212.872L.009.242A.185.185 0 01.185 0H.89c.125 0 .214.123.175.242l-.203.63A.185.185 0 01.685 1\"/>",
    "attrs": {
      "viewBox": "0 0 2 1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});