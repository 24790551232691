define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icons/info</title><g transform=\"translate(4 4)\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M8.73 11.904a.75.75 0 01-1.5 0V7.178a.75.75 0 111.5 0v4.726z\" fill=\"#009FF1\" fill-rule=\"nonzero\"/><circle fill=\"#009FF1\" cx=\"8\" cy=\"4\" r=\"1\"/><circle stroke=\"#211E5B\" stroke-width=\"1.5\" cx=\"8\" cy=\"8\" r=\"8\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});