define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0l1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.056l-4.702 3.416 1.796-5.528L.392 5.528h5.812L8 0z\"/>",
    "attrs": {
      "viewBox": "0 0 16 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});