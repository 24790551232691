define("ember-parachute/-private/symbols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PARACHUTE_QPS = _exports.PARACHUTE_META = _exports.HAS_PARACHUTE = void 0;
  const HAS_PARACHUTE = _exports.HAS_PARACHUTE = '__has_parachute__';
  const PARACHUTE_META = _exports.PARACHUTE_META = '__parachute_meta__';
  const PARACHUTE_QPS = _exports.PARACHUTE_QPS = '__parachute_qps__';
});