define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.295 227.66C.648 225.773 0 223.258 0 221.371c0-4.402 1.943-8.804 6.477-13.207L242.255 3.774C245.495 1.257 248.733 0 251.324 0l9.068 3.773 235.13 204.391c4.535 3.774 6.478 8.176 6.478 13.207 0 1.887-.648 4.402-1.295 6.29-2.591 6.917-9.069 10.69-18.137 10.69h-37.57V444h-140.56V320.108c0-6.918-5.829-11.95-12.306-11.95h-80.968c-7.125 0-12.307 5.66-12.307 11.95V444H57V238.351H19.432c-9.068 0-14.898-3.773-18.137-10.69z\"/>",
    "attrs": {
      "viewBox": "0 0 502 444"
    }
  };
});